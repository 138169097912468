.cancel{
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
  }

.box{
    display: block;
    position: relative;
    padding: 16px;
    border-radius: 4px;
    /* text-align: initial; */

}
.cancelHelperText{
    font-size: 14px !important;
    color: rgba(0,0,0,.54);
    margin-bottom: 1.5rem !important;
    text-align: initial;
}

.findButton{
    margin: 12px 0 0 !important;
    background-color: #00c853 !important;
}
.findButton:disabled{
    margin: 12px 0 0 !important;
    background-color: rgba(0,0,0,.12) !important;
}
.circularProgress{
    /* color: rgba(0,0,0,.12) !important; */
    text-align: center !important;
}