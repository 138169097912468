.App {
  text-align: center;
  width: 60%;
  background-color: #1976d2;
  margin: 100px auto;
  border-radius: 10px;
  display: block;
  padding: 15px;
}

header{
  padding: 0 10px 10px;
  margin-bottom: 15px;
  display: block;
  
}
.contact{
    float: right;
    text-align: right;
    line-height: 2.5em;
}
.clear-fix{
  clear: both;
}

.logo{
  text-align: initial;
  float: left;
  font-weight: 600;
  font-size: 1.5rem;
  width: 80%;

}

.matIcon {
  float: left;
  font-size: 20px;
  margin: 10px 5px 0 0;
}

.cancel{
  display: block;
  position: relative;
  padding: 16px;
  border-radius: 4px;
  padding-top:1.5rem
}

.notFoundText{
  color: rgba(0,0,0,.54);
  font-size: 24px !important;
  font-weight: 500 !important;
}
.errorCard{
  text-align: center;
  width: 80%;
  margin: auto;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 400px){
.App {
    width: 80%;
    margin: 100px auto;
}
.contact{
  float: left;
}

}