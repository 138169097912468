body {
  font-family: Roboto,sans-serif,Arial;
  background-color: #2196f3;
  color: #fff;
  display: block;
  margin: 8px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
